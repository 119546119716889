import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import { Layout } from '../components';
import {
  HomePage,
  TopicTemplateCategoryPage,
  TopicTemplatePage,
} from '../pages';

export const routes = [
  {
    element: HomePage,
    iconName: 'home',
    label: 'Home',
    path: '/',
  },
  {
    element: TopicTemplateCategoryPage,
    iconName: 'categories',
    label: 'Categories',
    path: '/topic-template-categories',
  },
  {
    element: TopicTemplatePage,
    iconName: 'topics',
    label: 'Topics',
    path: '/topic-templates',
  },
];

export const AppRoutes = () => {
  const appRoutes = routes.map((route) => {
    return (
      <Route key={route.path} path={route.path} element={<route.element />} />
    );
  });

  return createBrowserRouter(
    createRoutesFromElements(<Route element={<Layout />}>{appRoutes}</Route>)
  );
};
