import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMenu } from 'react-icons/fi';

import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  VStack,
} from '@chakra-ui/react';

import { TopicTemplate } from '../../models/topicTemplate';

interface IPopOverProps {
  topicTemplate: any;
  onEdit: (topicTemplate: TopicTemplate) => void;
  onDelete: (topic: TopicTemplate) => void;
}

export const ManagePopOver = ({
  topicTemplate,
  onEdit,
  onDelete,
}: IPopOverProps) => {
  const { t } = useTranslation();

  const handleEditTopic = useCallback(() => {
    onEdit(topicTemplate);
  }, [onEdit, topicTemplate]);

  const handleDeleteTopic = useCallback(() => {
    onDelete(topicTemplate);
  }, [onDelete, topicTemplate]);

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="outline">
          <FiMenu />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="8rem">
          <PopoverArrow />
          <PopoverBody>
            <VStack>
              <Button onClick={handleEditTopic} variant="outline">
                {t('common.edit')}
              </Button>
              <Button
                onClick={handleDeleteTopic}
                colorScheme="red"
                variant="outline"
              >
                {t('common.delete')}
              </Button>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
