import React from 'react';

import { Container, List } from '@chakra-ui/react';

import { routes } from '../../../../routes/app';
import { SideBarListItem } from './components/SideBarListItem/SideBarListItem';

const RouteList = () => (
  <>
    {routes.map(({ label, path, iconName }) => {
      return (
        <SideBarListItem
          key={label}
          link={path}
          label={label}
          iconName={iconName}
        />
      );
    })}
  </>
);

export const Sidebar = () => (
  <aside className="h-full">
    <Container>
      <List className="font-medium text-gray-500">
        <RouteList />
      </List>
    </Container>
  </aside>
);
