import { gql, useMutation, useQuery } from '@apollo/client';

export const CREATE_TOPIC_TEMPLATE = gql`
  mutation CreateTopicTemplate($topicTemplate: TopicTemplateInput!) {
    createTopicTemplate(topicTemplate: $topicTemplate) {
      id
      title
      description
      order
      coverImageUrl
      createdAt
      deletedAt
      items {
        title
        description
        order
        time
        speakers {
          name
          time
        }
      }
      categoryIds
    }
  }
`;

export const UPDATE_TOPIC_TEMPLATE = gql`
  mutation UpdateTopicTemplate($id: ID!, $topicTemplate: TopicTemplateInput!) {
    updateTopicTemplate(id: $id, topicTemplate: $topicTemplate) {
      id
      title
      description
      order
      coverImageUrl
      createdAt
      deletedAt
      items {
        title
        description
        order
        time
        speakers {
          name
          time
        }
      }
      categoryIds
    }
  }
`;

export const DELETE_TOPIC_TEMPLATE = gql`
  mutation DeleteTopicTemplate($id: ID!) {
    deleteTopicTemplate(id: $id)
  }
`;

export const GET_TOPIC_TEMPLATES = gql`
  query GetTopicTemplates {
    topicTemplates {
      categoryIds
      coverImageUrl
      createdAt
      deletedAt
      description
      id
      items {
        createdAt
        deletedAt
        description
        order
        speakers {
          name
          time
        }
        time
        title
      }
      order
      title
    }
  }
`;

export const GET_TOPIC_TEMPLATE = gql`
  query GetTopicTemplate($id: ID!) {
    topicTemplate(id: $id) {
      id
      title
      description
      order
      coverImageUrl
      createdAt
      deletedAt
      items {
        title
        description
        order
        time
        speakers {
          name
          time
        }
      }
      categoryIds
    }
  }
`;

export function useTopicTemplateMutations() {
  const [createTopicTemplate] = useMutation(CREATE_TOPIC_TEMPLATE);
  const [updateTopicTemplate] = useMutation(UPDATE_TOPIC_TEMPLATE);
  const [deleteTopicTemplate] = useMutation(DELETE_TOPIC_TEMPLATE);

  return { createTopicTemplate, updateTopicTemplate, deleteTopicTemplate };
}

export function useGetTopicTemplates() {
  return useQuery(GET_TOPIC_TEMPLATES);
}

export function useGetTopicTemplate(id: string) {
  return useQuery(GET_TOPIC_TEMPLATE, {
    variables: { id },
  });
}
