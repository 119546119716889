import React from 'react';

import { SignIn } from '@clerk/clerk-react';

export const SignInPage = () => {
  const signInStyles = {
    elements: {
      formButtonPrimary: 'bg-sky-950 hover:bg-sky-900',
    },
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-200">
      <SignIn appearance={signInStyles} />
    </div>
  );
};
