import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';

import { TopicTemplateItem } from '../../models/topicTemplate';

interface TopicItemFieldProps {
  item: TopicTemplateItem;
  onChange: (item: TopicTemplateItem) => void;
  onRemove: () => void;
}

export const TopicItemField = ({
  item,
  onChange,
  onRemove,
}: TopicItemFieldProps) => {
  const { t } = useTranslation();

  const handleFieldChange = (field: Partial<TopicTemplateItem>) => {
    onChange({
      ...item,
      ...field,
    });
  };

  return (
    <FormControl>
      <FormLabel>
        {t('topicTemplatePage.manageTopicTemplateForm.titleField')}
      </FormLabel>
      <Input
        value={item.title}
        onChange={(e) => handleFieldChange({ title: e.target.value })}
      />
      <FormLabel>
        {t('topicTemplatePage.manageTopicTemplateForm.descriptionField')}
      </FormLabel>
      <Input
        value={item.description}
        onChange={(e) => handleFieldChange({ description: e.target.value })}
      />
      <FormLabel>
        {t('topicTemplatePage.manageTopicTemplateForm.orderField')}
      </FormLabel>
      <NumberInput
        value={item.order}
        onChange={(valueString) =>
          handleFieldChange({ order: Number(valueString) })
        }
      >
        <NumberInputField />
      </NumberInput>
      <FormLabel>
        {t('topicTemplatePage.manageTopicTemplateForm.timeField')}
      </FormLabel>
      <NumberInput
        value={item.time}
        onChange={(valueString) =>
          handleFieldChange({ time: Number(valueString) })
        }
      >
        <NumberInputField />
      </NumberInput>
      <Button onClick={onRemove}>{t('common.remove')}</Button>
    </FormControl>
  );
};
