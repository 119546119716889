import React from 'react';
import { Outlet } from 'react-router-dom';

import { Container } from '@chakra-ui/react';

import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';

export const Layout = () => {
  return (
    <div className="h-screen">
      <Header></Header>
      <main className="flex h-full w-5/6 m-auto">
        <Sidebar></Sidebar>
        <Container minWidth="80%" className="p-5">
          <Outlet />
        </Container>
      </main>
    </div>
  );
};
