import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { TableData } from '../../types';

interface TableProps<T> {
  data: TableData<T>;
  renderActionCell: (rowData: T) => JSX.Element;
}

interface DataRow {
  id: string | number;
  [key: string]: any;
}

export const Table = <T extends DataRow>({
  data,
  renderActionCell,
}: TableProps<T>) => {
  const { t } = useTranslation();

  if (data.body.length === 0) {
    return <Text>{t('common.noRecordFound')}</Text>;
  }

  return (
    <TableContainer className="border border-gray-200 rounded-lg p-4 w-full">
      <ChakraTable variant="unstyled">
        <Thead>
          <Tr>
            {data.titles.concat('').map((title, index) => (
              <Th key={index}>{title}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.body.map((bodyItem: T) => (
            <Tr key={bodyItem.id} className="border-t">
              {Object.values(bodyItem)
                .filter((value) => value !== bodyItem.id)
                .map((bodyProp, index: number) => (
                  <Td key={index} fontWeight={index > 0 ? '400' : '500'}>
                    {bodyProp}
                  </Td>
                ))}
              <Td>
                <Box display="flex" justifyContent="flex-end">
                  {renderActionCell(bodyItem)}
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  );
};
