import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@chakra-ui/react';

export const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Text className="text-4xl text-red font-medium">
        {t('homePage.title')}
      </Text>
      <Text className="text-md my-4 mb-16">{t('homePage.description')}</Text>
    </div>
  );
};
