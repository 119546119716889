import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { ClerkProvider, SignedIn, SignedOut } from '@clerk/clerk-react';

import { AppRoutes, AuthRoutes } from './routes';

const App = () => {
  const clerkKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

  if (!clerkKey) {
    throw new Error('Missing Clerk Key');
  }

  return (
    <ClerkProvider publishableKey={clerkKey}>
      <SignedIn>
        <RouterProvider router={AppRoutes()} />
      </SignedIn>
      <SignedOut>
        <RouterProvider router={AuthRoutes()} />
      </SignedOut>
    </ClerkProvider>
  );
};

export default App;
