import { gql, useMutation, useQuery } from '@apollo/client';

export const CREATE_TOPIC_TEMPLATE_CATEGORY = gql`
  mutation CreateTopicTemplateCategory(
    $topicTemplateCategory: TopicTemplateCategoryInput!
  ) {
    createTopicTemplateCategory(topicTemplateCategory: $topicTemplateCategory) {
      id
      title
      description
      order
      isVisible
      coverImageUrl
    }
  }
`;

export const UPDATE_TOPIC_TEMPLATE_CATEGORY = gql`
  mutation UpdateTopicTemplateCategory(
    $id: ID!
    $topicTemplateCategory: TopicTemplateCategoryInput!
  ) {
    updateTopicTemplateCategory(
      id: $id
      topicTemplateCategory: $topicTemplateCategory
    ) {
      id
      title
      description
      order
      isVisible
      coverImageUrl
    }
  }
`;

export const DELETE_TOPIC_TEMPLATE_CATEGORY = gql`
  mutation DeleteTopicTemplateCategory($id: ID!) {
    deleteTopicTemplateCategory(id: $id)
  }
`;

export const GET_TOPIC_TEMPLATE_CATEGORIES = gql`
  query GetTopicTemplateCategories {
    topicTemplateCategories {
      id
      title
      description
      order
      isVisible
      coverImageUrl
    }
  }
`;

export const GET_TOPIC_TEMPLATE_CATEGORY = gql`
  query GetTopicTemplateCategory($id: ID!) {
    topicTemplateCategory(id: $id) {
      id
      title
      description
      order
      isVisible
      coverImageUrl
    }
  }
`;

export function useTopicTemplateCategoryMutations() {
  const [createTopicTemplateCategory] = useMutation(
    CREATE_TOPIC_TEMPLATE_CATEGORY
  );
  const [updateTopicTemplateCategory] = useMutation(
    UPDATE_TOPIC_TEMPLATE_CATEGORY
  );
  const [deleteTopicTemplateCategory] = useMutation(
    DELETE_TOPIC_TEMPLATE_CATEGORY
  );

  return {
    createTopicTemplateCategory,
    updateTopicTemplateCategory,
    deleteTopicTemplateCategory,
  };
}

export function useGetTopicTemplateCategories() {
  return useQuery(GET_TOPIC_TEMPLATE_CATEGORIES);
}

export function useGetTopicTemplateCategory(id: string) {
  return useQuery(GET_TOPIC_TEMPLATE_CATEGORY, {
    variables: { id },
  });
}
