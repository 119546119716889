import React from 'react';
import Select from 'react-select';

import { FormLabel } from '@chakra-ui/react';

interface Option {
  value: string;
  label: string;
}

interface MultiSelectProps {
  options: Option[];
  value: Option[];
  onChange: (value: Option[]) => void;
  label: string;
}

export const MultiSelect = ({
  options,
  value,
  label,
  onChange,
}: MultiSelectProps) => (
  <>
    <FormLabel>{label}</FormLabel>
    <Select
      isMulti
      options={options}
      value={value}
      onChange={onChange as any}
    />
  </>
);
