import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

interface ConfirmDialogProps {
  isOpen: boolean;
  title?: string;
  description?: string;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDialog = ({
  isOpen,
  title = 'common.confirmDialog.title',
  description = 'common.confirmDialog.description',
  confirmBtnLabel = 'common.confirmDialog.confirmBtn',
  cancelBtnLabel = 'common.confirmDialog.cancelBtn',
  onConfirm,
  onCancel,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const cancelRef = React.useRef<HTMLElement>(null);

  useEffect(() => {
    if (isOpen) {
      setLoading(false);
    }
  }, [isOpen]);

  const handleConfirm = () => {
    setLoading(true);
    onConfirm();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t(title)}
          </AlertDialogHeader>

          <AlertDialogBody>{t(description)}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onCancel}>{t(cancelBtnLabel)}</Button>
            <Button
              isLoading={isLoading}
              colorScheme="red"
              onClick={handleConfirm}
              ml={3}
            >
              {t(confirmBtnLabel)}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
