import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Spinner, Text, VStack } from '@chakra-ui/react';

import { ConfirmDialog } from '../../../components/ConfirmDialog';
import { Table } from '../../../components/Table';
import { ManageCategoryPopOver } from './components/ManagePopOver';
import { ManageCategory } from './components/ManageTopicTemplateCategory';
import {
  useGetTopicTemplateCategories,
  useTopicTemplateCategoryMutations,
} from './hooks';
import { TopicTemplateCategory } from './models/topicTemplateCategory';

export const TopicTemplateCategoryPage = () => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [topicTemplateCategories, setTopicTemplateCategories] = useState<
    TopicTemplateCategory[]
  >([]);
  const [activeTopicTemplateCategory, setActiveTopicTemplateCategory] =
    useState<{
      topicTemplateCategory: TopicTemplateCategory | null;
      action: 'edit' | 'delete' | null;
    }>({
      topicTemplateCategory: null,
      action: null,
    });

  const {
    createTopicTemplateCategory,
    deleteTopicTemplateCategory,
    updateTopicTemplateCategory,
  } = useTopicTemplateCategoryMutations();

  // load categories
  const { data, loading } = useGetTopicTemplateCategories();

  useEffect(() => {
    if (data) {
      setTopicTemplateCategories(data.topicTemplateCategories);
    }
  }, [data]);

  const transformedData = transformData(topicTemplateCategories);

  const handleDeleteClick = async () => {
    if (
      activeTopicTemplateCategory.action === 'delete' &&
      activeTopicTemplateCategory.topicTemplateCategory
    ) {
      await deleteTopicTemplateCategory({
        variables: {
          id: activeTopicTemplateCategory.topicTemplateCategory?.id,
        },
      });
      setTopicTemplateCategories(
        topicTemplateCategories.filter(
          (topicTemplateCategory) =>
            topicTemplateCategory.id !==
            activeTopicTemplateCategory.topicTemplateCategory?.id
        )
      );
    }

    setActiveTopicTemplateCategory({
      topicTemplateCategory: null,
      action: null,
    });
    setConfirmDialogOpen(false);
  };

  const handleSubmitTopicTemplateCategory = async (
    topicTemplateCategory: TopicTemplateCategory
  ) => {
    setLoading(true);

    if (topicTemplateCategory.id) {
      const { id, ...topicTemplateCategoryInput } = topicTemplateCategory;
      await updateTopicTemplateCategory({
        variables: {
          id,
          topicTemplateCategory: {
            ...topicTemplateCategoryInput,
            order: parseInt(`${topicTemplateCategoryInput.order}`),
          },
        },
      });
    } else {
      const { data } = await createTopicTemplateCategory({
        variables: {
          topicTemplateCategory: {
            ...topicTemplateCategory,
            order: parseInt(`${topicTemplateCategory.order}`),
          },
        },
      });
      setTopicTemplateCategories([
        ...topicTemplateCategories,
        data.createTopicTemplateCategory,
      ]);
    }

    setLoading(false);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setActiveTopicTemplateCategory({
      topicTemplateCategory: null,
      action: null,
    });
    setModalOpen(false);
  };

  return (
    <div>
      <Text className="text-4xl text-red font-medium">
        {t('topicTemplateCategoryPage.title')}
      </Text>
      <Text className="text-md my-4 mb-16">
        {t('topicTemplateCategoryPage.description')}
      </Text>
      <VStack spacing={4}>
        <Button
          onClick={() => setModalOpen(true)}
          variant="outline"
          colorScheme="dark"
          className="mb-4"
          alignSelf="flex-end"
        >
          {t('topicTemplateCategoryPage.createTopicTemplateCategory')}
        </Button>
        {loading ? (
          <Spinner />
        ) : (
          <Table
            data={transformedData}
            renderActionCell={(topicTemplateCategory) => (
              <ManageCategoryPopOver
                topicTemplateCategory={topicTemplateCategory}
                onEdit={(topicTemplateCategory) => {
                  setActiveTopicTemplateCategory({
                    topicTemplateCategory,
                    action: 'edit',
                  });
                  setModalOpen(true);
                }}
                onDelete={() => {
                  setActiveTopicTemplateCategory({
                    topicTemplateCategory,
                    action: 'delete',
                  });
                  setConfirmDialogOpen(true);
                }}
              />
            )}
          />
        )}
        <ManageCategory
          isLoading={isLoading}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleSubmitTopicTemplateCategory}
          initialCategory={
            activeTopicTemplateCategory.action === 'edit'
              ? activeTopicTemplateCategory.topicTemplateCategory
              : null
          }
        />
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          onConfirm={handleDeleteClick}
          onCancel={() => setConfirmDialogOpen(false)}
        />
      </VStack>
    </div>
  );
};

const transformData = (topicTemplateCategories: TopicTemplateCategory[]) => ({
  titles: ['Title', 'Description', 'Order'],
  body: topicTemplateCategories
    ? topicTemplateCategories.map((topicTemplateCategory) => ({
        id: topicTemplateCategory.id || '',
        title: topicTemplateCategory.title,
        description: topicTemplateCategory.description || '',
        order: topicTemplateCategory.order,
      }))
    : [],
});
