import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Spinner, Text, VStack } from '@chakra-ui/react';

import { ConfirmDialog } from '../../../components/ConfirmDialog';
import { Table } from '../../../components/Table';
import { ManagePopOver } from './components/ManagePopOver';
import { ManageTopic } from './components/ManageTopicTemplate';
import { useGetTopicTemplates, useTopicTemplateMutations } from './hooks';
import { TopicTemplate } from './models/topicTemplate';

export const TopicTemplatePage = () => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [topicTemplates, setTopicTemplates] = useState<TopicTemplate[]>([]);
  const [activeTopicTemplate, setActiveTopicTemplate] = useState<{
    action: 'edit' | 'delete' | null;
    topicTemplate: any;
  }>({
    action: null,
    topicTemplate: null,
  });

  const { createTopicTemplate, deleteTopicTemplate, updateTopicTemplate } =
    useTopicTemplateMutations();

  //load topics
  const { data, loading } = useGetTopicTemplates();

  useEffect(() => {
    if (data) {
      setTopicTemplates(data.topicTemplates);
    }
  }, [data]);

  const handleDeleteClick = async () => {
    if (
      activeTopicTemplate.action === 'delete' &&
      activeTopicTemplate.topicTemplate
    ) {
      await deleteTopicTemplate({
        variables: { id: activeTopicTemplate.topicTemplate.id },
      });
      setTopicTemplates(
        topicTemplates.filter(
          (topic) => topic.id !== activeTopicTemplate.topicTemplate?.id
        )
      );
    }
    setActiveTopicTemplate({ topicTemplate: null, action: null });
    setConfirmDialogOpen(false);
  };

  const handleSubmitTopic = async (topicTemplate: TopicTemplate) => {
    setLoading(true);

    if (topicTemplate.id) {
      const { id, ...topicInput } = topicTemplate;
      await updateTopicTemplate({
        variables: { id, topicTemplate: topicInput },
      });
    } else {
      const { data } = await createTopicTemplate({
        variables: { topicTemplate },
      });
      setTopicTemplates([...topicTemplates, data.createTopicTemplate]);
    }

    setLoading(false);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setActiveTopicTemplate({ topicTemplate: null, action: null });
    setModalOpen(false);
  };

  const transformedData = transformData(topicTemplates);

  return (
    <div>
      <Text className="text-4xl text-red font-medium">
        {t('topicTemplatePage.title')}
      </Text>
      <Text className="text-md my-4 mb-16">
        {t('topicTemplatePage.description')}
      </Text>
      <VStack spacing={4}>
        <Button
          onClick={() => setModalOpen(true)}
          variant="outline"
          colorScheme="dark"
          className="bg-red-500 mb-4"
          alignSelf="flex-end"
        >
          {t('topicTemplatePage.createTopicTemplate')}
        </Button>

        {loading ? (
          <Spinner />
        ) : (
          <Table
            data={transformedData}
            renderActionCell={(topicTemplate) => (
              <ManagePopOver
                topicTemplate={topicTemplate}
                onEdit={(selectedTemplate) => {
                  const topicTemplate = data.topicTemplates.find(
                    (topicTemplate: any) => topicTemplate.id === selectedTemplate.id //eslint-disable-line
                  );
                  setActiveTopicTemplate({ topicTemplate, action: 'edit' });
                  setModalOpen(true);
                }}
                onDelete={() => {
                  setActiveTopicTemplate({ topicTemplate, action: 'delete' });
                  setConfirmDialogOpen(true);
                }}
              />
            )}
          />
        )}
        <ManageTopic
          isLoading={isLoading}
          isOpen={isModalOpen}
          initialTopicTemplate={activeTopicTemplate.topicTemplate}
          onClose={handleCloseModal}
          onSubmit={handleSubmitTopic}
        />
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          onConfirm={handleDeleteClick}
          onCancel={() => setConfirmDialogOpen(false)}
        />
      </VStack>
    </div>
  );
};

const transformData = (topics: TopicTemplate[] = []) => ({
  titles: ['Title', 'Description', 'Order'],
  body: topics.map((topic) => ({
    id: topic.id || '',
    title: topic.title,
    description: topic.description || '',
    order: topic.order || '',
  })),
});
