import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';

interface ControlledFieldProps {
  name: string;
  control: Control<any>;
  defaultValue: unknown;
  rules?: RegisterOptions;
  label: string;
  type: string;
  errors: any;
}

export const ControlledField = ({
  name,
  control,
  defaultValue,
  rules,
  label,
  type,
  errors,
}: ControlledFieldProps) => (
  <FormControl className="my-4">
    <FormLabel>{label}</FormLabel>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) =>
        type === 'number' ? (
          <NumberInput min={0} {...field}>
            <NumberInputField />
          </NumberInput>
        ) : (
          <Input type={type} {...field} />
        )
      }
    />
    {errors[name] && <p className="text-red-500">{errors[name]?.message}</p>}
  </FormControl>
);
