import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import enUSTranslation from '../../assets/locales/en_US.json';

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources: {
    'en-US': {
      translation: enUSTranslation,
    },
  },
  lng: 'en-US',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
