import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';

import { ControlledField } from '../../../../../components/Form';
import { MultiSelect } from '../../../../../components/Form/MultiSelect';
import { useGetTopicTemplateCategories } from '../../../TopicTemplateCategoryPage/hooks';
import { TopicTemplateCategory } from '../../../TopicTemplateCategoryPage/models/topicTemplateCategory';
import { TopicTemplate, TopicTemplateItem } from '../../models/topicTemplate';
import { TopicItemField } from './TopicTemplateItemField';

interface TopicTemplateFormProps {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (topic: TopicTemplate) => void;
  initialTopicTemplate?: TopicTemplate | null;
}

export const ManageTopicTemplate = ({
  isLoading,
  isOpen,
  onClose,
  onSubmit,
  initialTopicTemplate,
}: TopicTemplateFormProps) => {
  const { t } = useTranslation();

  const defaultValues = initialTopicTemplate || {
    title: '',
    description: '',
    order: 0,
    items: [],
    categoryIds: [],
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<TopicTemplate>({ defaultValues });

  const [topicTemplateCategories, setTopicTemplateCategories] = useState<
    TopicTemplateCategory[]
  >([]);
  const [selectedTopicTemplateCategories, setSelectedTopicTemplateCategories] =
    useState<any[]>([]);
  const [topicTemplateItems, setTopicTemplateItems] = useState<
    TopicTemplateItem[]
  >(initialTopicTemplate?.items || []);

  const { data } = useGetTopicTemplateCategories();

  const loadCategories = useCallback(() => {
    if (data) {
      setTopicTemplateCategories(data.topicTemplateCategories);
    }
  }, [data]);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
      setTopicTemplateItems(
        initialTopicTemplate ? initialTopicTemplate.items || [] : []
      );

      const selected_categories = topicTemplateCategories
        .filter((category: any) => initialTopicTemplate?.categoryIds?.includes(category.id)) //eslint-disable-line
        .map((category) => ({
          value: category.id,
          label: category.title,
        }));

      setSelectedTopicTemplateCategories(selected_categories);
    }
  }, [isOpen, initialTopicTemplate, reset]);

  const categoryOptions: any[] = topicTemplateCategories
    .filter((category) => category.id !== undefined)
    .map((category) => ({
      value: category.id,
      label: category.title,
    }));

  const handleTopicTemplateItemChange = (
    index: number,
    item: TopicTemplateItem
  ) => {
    const newItems = [...topicTemplateItems];
    newItems[index] = item;
    setTopicTemplateItems(newItems);
  };

  const handleAddTopicTemplateItem = () => {
    setTopicTemplateItems([
      ...topicTemplateItems,
      {
        title: '',
        description: '',
        order: topicTemplateItems.length,
        time: 0,
      },
    ]);
  };

  const handleRemoveTopicTemplateItem = (index: number) => {
    const newItems = [...topicTemplateItems];
    newItems.splice(index, 1);
    setTopicTemplateItems(newItems);
  };

  const cleanDataForSubmission = (data: any) => {
    const cleanedData = { ...data };

    delete cleanedData.__typename;
    delete cleanedData.createdAt;
    delete cleanedData.deletedAt;

    if (cleanedData.items) {
      cleanedData.items = cleanedData.items.map((item: any) => {
        const cleanedItem = { ...item };
        delete cleanedItem.__typename;
        delete cleanedItem.createdAt;
        delete cleanedItem.deletedAt;
        return cleanedItem;
      });
    }

    return cleanedData;
  };

  const handleSubmitForm = (data: TopicTemplate) => {
    const cleanedData = cleanDataForSubmission({
      ...data,
      items: topicTemplateItems,
      order: parseInt(`${data.order}`),
      categoryIds: selectedTopicTemplateCategories.map(
        (category) => category.value
      ),
    });

    onSubmit(cleanedData);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleSubmitForm)}>
        <ModalHeader>
          {t('topicTemplatePage.manageTopicTemplateForm.title')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ControlledField
            name="title"
            control={control}
            defaultValue=""
            rules={{
              required: t(
                'topicTemplatePage.manageTopicTemplateForm.validation.titleRequired'
              ),
            }}
            label={t('topicTemplatePage.manageTopicTemplateForm.titleField')}
            type="text"
            errors={errors}
          />
          <ControlledField
            name="description"
            control={control}
            defaultValue=""
            label={t(
              'topicTemplatePage.manageTopicTemplateForm.descriptionField'
            )}
            type="text"
            errors={errors}
          />
          <ControlledField
            name="order"
            control={control}
            defaultValue={0}
            rules={{
              min: {
                value: 0,
                message: t(
                  'topicTemplatePage.manageTopicTemplateForm.validation.orderPositive'
                ),
              },
            }}
            label={t('topicTemplatePage.manageTopicTemplateForm.orderField')}
            type="number"
            errors={errors}
          />
          <MultiSelect
            label={'Categories'}
            onChange={(value) => setSelectedTopicTemplateCategories(value)}
            value={selectedTopicTemplateCategories}
            options={categoryOptions}
          />
          <VStack spacing={4} mt={4}>
            {topicTemplateItems.map((item, index) => (
              <TopicItemField
                key={index}
                item={item}
                onChange={(item) => handleTopicTemplateItemChange(index, item)}
                onRemove={() => handleRemoveTopicTemplateItem(index)}
              />
            ))}
            <Button onClick={handleAddTopicTemplateItem}>+</Button>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button isLoading={isLoading} type="submit">
            {t('common.save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
