import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';

import { SignInPage } from '../pages/Auth';

export const routes = [
  {
    element: <Navigate to="/sign-in" />,
    path: '*',
  },
  {
    element: <SignInPage />,
    path: '/sign-in',
  },
];

export const AuthRoutes = () => {
  const authRoutes = routes.map((route) => {
    return <Route key={route.path} path={route.path} element={route.element} />;
  });

  return createBrowserRouter(
    createRoutesFromElements(<Route>{authRoutes}</Route>)
  );
};
