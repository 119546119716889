import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { ControlledField } from '../../../../../components/Form';
import { TopicTemplateCategory } from '../../models/topicTemplateCategory';

interface TopicTemplateCategoryFormProps {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (topicTemplateCategory: TopicTemplateCategory) => void;
  initialCategory?: TopicTemplateCategory | null;
}

export const ManageTopicTemplateCategory = ({
  isLoading,
  isOpen,
  onClose,
  onSubmit,
  initialCategory,
}: TopicTemplateCategoryFormProps) => {
  const { t } = useTranslation();

  const defaultValues = initialCategory || {
    title: '',
    description: '',
    order: 0,
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<TopicTemplateCategory>({ defaultValues });

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, initialCategory, reset]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          {t('topicTemplateCategoryPage.manageTopicTemplateCategoryForm.title')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ControlledField
            name="title"
            control={control}
            defaultValue=""
            rules={{
              required: t(
                'topicTemplateCategoryPage.manageTopicTemplateCategoryForm.validation.titleRequired'
              ),
            }}
            label={t(
              'topicTemplateCategoryPage.manageTopicTemplateCategoryForm.titleField'
            )}
            type="text"
            errors={errors}
          />
          <ControlledField
            name="description"
            control={control}
            defaultValue=""
            label={t(
              'topicTemplateCategoryPage.manageTopicTemplateCategoryForm.descriptionField'
            )}
            type="text"
            errors={errors}
          />
          <ControlledField
            name="order"
            control={control}
            defaultValue={0}
            rules={{
              min: {
                value: 0,
                message: t(
                  'topicTemplateCategoryPage.manageTopicTemplateCategoryForm.validation.orderPositive'
                ),
              },
            }}
            label={t(
              'topicTemplateCategoryPage.manageTopicTemplateCategoryForm.orderField'
            )}
            type="number"
            errors={errors}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button isLoading={isLoading} type="submit">
            {t('common.save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
