import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMenu } from 'react-icons/fi';

import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  VStack,
} from '@chakra-ui/react';

import { TopicTemplateCategory } from '../../models/topicTemplateCategory';

interface IPopOverProps {
  topicTemplateCategory: TopicTemplateCategory;
  onEdit: (topicTemplateCategory: TopicTemplateCategory) => void;
  onDelete: (topicTemplateCategory: TopicTemplateCategory) => void;
}

export const ManageTopicTemplateCategoryPopOver = ({
  topicTemplateCategory,
  onEdit,
  onDelete,
}: IPopOverProps) => {
  const { t } = useTranslation();

  const handleEditTopicTemplateCategory = useCallback(() => {
    onEdit(topicTemplateCategory);
  }, [onEdit, topicTemplateCategory]);

  const handleDeleteTopicTemplateCategory = useCallback(() => {
    onDelete(topicTemplateCategory);
  }, [onDelete, topicTemplateCategory]);

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="outline">
          <FiMenu />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="8rem">
          <PopoverArrow />
          <PopoverBody>
            <VStack>
              <Button
                onClick={handleEditTopicTemplateCategory}
                variant="outline"
              >
                {t('common.edit')}
              </Button>
              <Button
                onClick={handleDeleteTopicTemplateCategory}
                colorScheme="red"
                variant="outline"
              >
                {t('common.delete')}
              </Button>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
