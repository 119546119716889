import React from 'react';
import { IconType } from 'react-icons';
import { FiCalendar, FiGrid, FiHome } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';

import { Flex, ListItem, Text } from '@chakra-ui/react';

interface SideBarListItemProps {
  link: string;
  label: string;
  iconName: string;
}

const ICONS: { [iconName: string]: IconType } = {
  home: FiHome,
  topics: FiCalendar,
  categories: FiGrid,
};

export const SideBarListItem = ({
  label,
  link,
  iconName,
}: SideBarListItemProps) => {
  const IconComponent = ICONS[iconName];
  const location = useLocation();

  return (
    <Link to={link}>
      <ListItem className="rounded text-sm text-black py-4">
        <Flex align="center">
          {IconComponent && <IconComponent className="mx-4" />}
          <Text fontWeight={location.pathname === link ? 'bold' : 'normal'}>
            {label}
          </Text>
        </Flex>
      </ListItem>
    </Link>
  );
};
