import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Box, Button, HStack, Image } from '@chakra-ui/react';
import { useClerk, useUser } from '@clerk/clerk-react';
import { UserResource } from '@clerk/types';

import Logo from '../../../../assets/images/logo.svg';

interface HeaderControlsProps {
  user?: UserResource | null;
  onSignOut?: () => void;
}

export const Header = () => {
  const { user } = useUser();
  const { signOut } = useClerk();

  return (
    <header className="z-10 h-20 w-full border-b border-grey-200 sticky top-0 left-0 right-0 bg-white mb-4  shadow-lg">
      <Box className="h-full w-5/6 flex justify-between items-center m-auto text-sm font-medium">
        <HeaderLogo />
        <HeaderControls user={user} onSignOut={signOut} />
      </Box>
    </header>
  );
};

const HeaderLogo = () => <Image width={50} src={Logo}></Image>;

const HeaderControls = ({ user, onSignOut }: HeaderControlsProps) => (
  <HStack spacing={16}>
    <UserAvatar user={user} />
    <SignOutButton onSignOut={onSignOut} />
  </HStack>
);

const UserAvatar = ({ user }: HeaderControlsProps) => (
  <Avatar name={user?.fullName || ''} src={user?.imageUrl} />
);

const SignOutButton = ({ onSignOut }: HeaderControlsProps) => {
  const { t } = useTranslation();

  return (
    <Button onClick={onSignOut} colorScheme="dark" variant="outline">
      {t('header.signOut')}
    </Button>
  );
};
